<template>
  <div class="appeals-about article">
    <p>
      Данный сервис предоставляет Вам возможность направить обращение в любой исполнительный орган Республики
      Дагестан и орган местного самоуправления Республики Дагестан.
    </p>
    <p style="margin-bottom: 20px">
      Прежде чем отправить обращение через единую интернет-приемную, посмотрите раздел «Часто задаваемые
      вопросы». Возможно, Вы сразу найдете информацию на интересующую Вас тему.
    </p>
    <div class="notify notify--yellow">
      Информируем, что размещение данных о результатах рассмотрения обращений граждан, объединений граждан, в
      том числе юридических лиц, в разделе «Личный кабинет» не предусмотрено Федеральным законом Российской
      Федерации от 02.05.2006 № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации».
    </div>
    <p v-if="$store.state.department.id === 7">
      Ответственный по вопросам рассмотрения обращений граждан в Минтруда РД - Начальник общего отдела
      Минтруда РД Магомедова И.Н. 67-94-63
    </p>
  </div>
</template>

<script>
export default {
  name: "AppealsAbout",
  metaInfo: {
    title: "Порядок рассмотрения обращений граждан",
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/article.styl"
@import "~@/styles/parts/notify.styl"
</style>
